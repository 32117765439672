<template>
  <post :post="postData"></post>
</template>

<script>
import postData from '../mock/AboutUs.json'
import Post from '../components/Post'
export default {
  name: 'About',
  components: { Post },
  data: () => ({
    postData: postData
  })
}
</script>

<style scoped>

</style>
